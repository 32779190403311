import { useState } from "react";
import {
  Flex,
  Divider,
  useColorMode,
  useColorModeValue,
  Accordion,
  AccordionItem,
  AccordionPanel,
  AccordionButton,
  AccordionIcon,
  Box,
  Icon,
} from "@chakra-ui/react";
import {
  FiHome,
  FiLogIn,
  FiSettings,
  FiCheckCircle,
  FiTruck,
  FiXCircle,
  FiClipboard,
  FiImage,
  FiShoppingCart,
  FiDollarSign,
  FiTrendingUp,
  FiDatabase,
} from "react-icons/fi";
import { useAuth } from "../../contexts/AuthContext";
import NavItem from "./NavItem";
import { BsBank, BsClockHistory, BsFillMoonStarsFill } from "react-icons/bs";
import SubmissionDrawer from "../../pages/Loans/SubmissionDrawer";
import {
  FaBullhorn,
  FaFileInvoiceDollar,
  FaNewspaper,
  FaPoll,
  FaRegQuestionCircle,
  FaStoreAlt,
  FaTshirt,
} from "react-icons/fa";
import CreateRequest from "../Marketing/CreateRequest";
import { MdOutlineDashboard } from "react-icons/md";
import { RiGuideFill } from "react-icons/ri";
import { GrCompliance } from "react-icons/gr";
import { AiFillDashboard } from "react-icons/ai";
import { GiPlanetConquest } from "react-icons/gi";
import { BiPlanet, BiSolidPlanet } from "react-icons/bi";

export default function Sidebar() {
  const [submissionsMenuIndex, setSubmissionsMenuIndex] = useState();
  const [marketingMenuIndex, setMarketingMenuIndex] = useState();
  const [loading, setLoading] = useState(false);

  const { toggleColorMode } = useColorMode();
  const {
    logout,
    currentUser,
    navSize,
    changeNavSize,
    display,
    isAdmin,
    SuperAdmin,
    MarketingAdmin,
  } = useAuth();
  const { setupQueueCount } = useAuth();
  const handleNavbarToggle = () => {
    if (navSize != "small") changeNavSize("small");
    else changeNavSize("large");
  };

  return (
    <Flex
      h="91vh"
      display={{ base: "none", md: "flex" }}
      boxShadow="0 12px 12px 0 rgba(0, 0, 0, 0.05)"
      w={navSize == "small" ? "85px" : "200px"}
      backgroundColor={useColorModeValue("white", "midnight")}
      flexDir="column"
      justifyContent="flex-start"
    >
      {!loading && (
        <>
          <Flex
            flexDir="column"
            width={"100%"}
            alignItems={navSize == "small" ? "center" : "flex-start"}
            as="nav"
          >
            <NavItem navSize={navSize} icon={FiHome} name="Home" to="/" />
            <Divider />
            <NavItem
              navSize={navSize}
              icon={AiFillDashboard}
              name="My Dashboard"
              to="/my-dashboard"
            />
            <Divider />
            <NavItem
              navSize={navSize}
              icon={FiDollarSign}
              name="Comp Dashboard"
              to="/comp-dashboard"
            />
            <Divider />
            <NavItem
              navSize={navSize}
              icon={FiTrendingUp}
              name="Sales Dashboard"
              to="/sales-dashboard"
            />
            <Divider />
            <Accordion
              w="100%"
              onChange={(index) => setSubmissionsMenuIndex(index)}
              allowToggle
              defaultIndex={submissionsMenuIndex}
            >
              <AccordionItem>
                <h2>
                  <AccordionButton w="100%" pt="9%" pb="9%">
                    <Icon as={FaFileInvoiceDollar} />
                    {navSize != "small" && (
                      <Box pl="2" textAlign="left">
                        Submissions
                      </Box>
                    )}
                    <AccordionIcon alignSelf="flex-end" />
                  </AccordionButton>
                </h2>
                <AccordionPanel p={0}>
                  <Divider />
                  <SubmissionDrawer
                    isFirstChild
                    isChild
                    action="Add"
                    navSize={navSize}
                    isNavButton={true}
                  />
                  <Divider />
                  <NavItem
                    navSize={navSize}
                    isChild
                    setupQueueCount={setupQueueCount}
                    icon={FiLogIn}
                    name="Setup Queue"
                    to="/setup-queue"
                  />
                  <Divider />
                  <NavItem
                    navSize={navSize}
                    isChild
                    icon={BsClockHistory}
                    name="In Progress"
                    to="/in-progress"
                  />
                  <Divider />
                  <NavItem
                    navSize={navSize}
                    isChild
                    icon={FiTruck}
                    name="Parked"
                    to="/parked"
                  />
                  <Divider />
                  <NavItem
                    navSize={navSize}
                    isChild
                    icon={FaRegQuestionCircle}
                    name="TBD"
                    to="/tbd"
                  />
                  <Divider />
                  <NavItem
                    navSize={navSize}
                    isChild
                    icon={FiCheckCircle}
                    name="Completed"
                    to="/completed"
                  />
                  <Divider />
                  <NavItem
                    navSize={navSize}
                    isLastChild={!isAdmin && !SuperAdmin ? true : false}
                    isChild
                    icon={FiXCircle}
                    name="Not Submitted"
                    to="/not-submitted"
                  />
                  {(isAdmin || SuperAdmin) && (
                    <>
                      <Divider />
                      <NavItem
                        navSize={navSize}
                        isLastChild
                        isChild
                        icon={FiSettings}
                        name="Settings"
                        to="/submission-settings"
                      />
                    </>
                  )}
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
            <Accordion
              w="100%"
              onChange={(index) => setMarketingMenuIndex(index)}
              allowToggle
              defaultIndex={marketingMenuIndex}
            >
              <AccordionItem>
                <h2>
                  <AccordionButton w="100%" pt="9%" pb="9%">
                    <Icon as={FaBullhorn} />
                    {navSize != "small" && (
                      <Box pl="2" textAlign="left">
                        Marketing
                      </Box>
                    )}
                    <AccordionIcon alignSelf="flex-end" />
                  </AccordionButton>
                </h2>
                <AccordionPanel p={0}>
                  <Divider />
                  <CreateRequest
                    isFirstChild
                    isChild
                    action="Create"
                    navSize={navSize}
                    isNavButton={true}
                  />
                  <Divider />
                  <NavItem
                    navSize={navSize}
                    isChild
                    icon={FiClipboard}
                    name="Requests"
                    to="/marketing-requests"
                  />
                  <Divider />
                  <NavItem
                    navSize={navSize}
                    isChild
                    icon={FiShoppingCart}
                    name="Swag"
                    to="/swag"
                  />
                  <Divider />
                  <NavItem
                    navSize={navSize}
                    isChild
                    icon={BiSolidPlanet}
                    name="Matrix"
                    to="/marketing-matrix"
                  />
                  <Divider />

                  <NavItem
                    navSize={navSize}
                    isExternal
                    isChild
                    icon={BsBank}
                    name="Vault"
                    to="https://drive.google.com/drive/folders/1-l2aNJDhCJyc89HnHbeDt3xKxn4hkb4Z"
                  />
                  <Divider />
                  <NavItem
                    navSize={navSize}
                    isExternal
                    isChild
                    icon={FaTshirt}
                    name="SquadLocker"
                    to="https://teamlocker.squadlocker.com/#/lockers/future-home-loans"
                  />
                  <Divider />
                  <NavItem
                    navSize={navSize}
                    isChild
                    icon={FiImage}
                    name="FHL Logos"
                    to="/logos"
                  />
                  <Divider />
                  <NavItem
                    navSize={navSize}
                    isChild
                    icon={GrCompliance}
                    name="SM Compliance"
                    to="/social-media-compliance"
                  />
                  <Divider />
                  {(MarketingAdmin || SuperAdmin) && (
                    <>
                      <Divider />
                      <NavItem
                        navSize={navSize}
                        isChild
                        icon={FaNewspaper}
                        name="Newsletters"
                        to="/monthly-newsletters"
                      />
                      <Divider />
                      <NavItem
                        navSize={navSize}
                        isChild
                        icon={FaStoreAlt}
                        name="Inventory"
                        to="/marketing-inventory"
                      />
                      <Divider />
                      <NavItem
                        navSize={navSize}
                        isChild
                        icon={RiGuideFill}
                        name="Guidelines"
                        to="/marketing-swag-guidelines"
                      />
                      <Divider />
                      <NavItem
                        navSize={navSize}
                        isChild
                        icon={FiDatabase}
                        name="Dashboard"
                        to="/marketing-dashboard"
                      />
                      <Divider />
                      <NavItem
                        navSize={navSize}
                        isChild
                        icon={FaPoll}
                        name="Reports (beta)"
                        to="/marketing-reports"
                      />
                      <Divider />
                      <NavItem
                        navSize={navSize}
                        isLastChild
                        isChild
                        icon={FiSettings}
                        name="Settings"
                        to="/marketing-settings"
                      />
                    </>
                  )}
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
            <Divider />
            <NavItem
              animated
              navSize={navSize}
              icon={BsBank}
              name="FHL Directory"
              to="/broker-directory"
            />
            <Divider />
            <NavItem
              navSize={navSize}
              icon={AiFillDashboard}
              name="Escalations"
              to="/escalations"
            />
            {SuperAdmin == true ? (
              <>
                {/*           <Divider/>
                    <NavItem navSize={navSize} icon={BiChalkboard} name="Training" to="/training"/>   */}
                <Divider />
                <NavItem
                  navSize={navSize}
                  icon={FiSettings}
                  name="Admin"
                  to="/admin"
                />
                <Divider />
                <NavItem
                  navSize={navSize}
                  icon={FaPoll}
                  name="Loan Reporting"
                  to="/loan-reporting"
                />
                <Divider />
                <NavItem
                  navSize={navSize}
                  icon={MdOutlineDashboard}
                  name="Ops Dashboard"
                  to="/ops-dashboard"
                />
              </>
            ) : null}

            {/* {installable &&
                <>
                    <Divider/>
                    {navSize == 'large' ? 
                        <NavButton onOpen={handleInstallClick} name='Install App' label='Install App' icon={FiDownload}>
                        </NavButton>
                    :
                        <IconButton onClick={handleInstallClick} colorScheme='blackAlpha' variant='ghost' name='Install App' icon={<FiDownload/>}>
                        </IconButton>
                    }
                </>
                 } */}
          </Flex>
        </>
      )}
    </Flex>
  );
}
