import { useState, useEffect } from "react";
import { Center, Heading, useColorModeValue, Flex, SimpleGrid, FormControl, IconButton, useToast } from "@chakra-ui/react";
import { datepickerLight, datepickerDark } from "../../contexts/GlobalVariables";
import { getDocs, limit, orderBy, query, collection, doc, deleteDoc } from "firebase/firestore";
import { db } from "../../utils/init-firebase";
import { useAuth } from "../../contexts/AuthContext";
import TopPerformers_Edit from "./TopPerformers_Edit";
import TopPerformers_IndividualBox from "./TopPerformers_IndividualBox";
import TopPerformers_GoogleReviews from "./TopPerformers_GoogleReviews";
import TopPerformers_CompanyStats from "./TopPerformers_CompanyStats";
import { Select } from "chakra-react-select";
import TopPerformers_ReviewCarousel_V2 from "./TopPerformers_ReviewCarousel_V2";
import { FiTrash2 } from "react-icons/fi";

export default function TopPerformers({ }) {
    const { SuperAdmin } = useAuth()
    const [displayedMonth, setDisplayedMonth] = useState(null)
    const [previousMonth, setPreviousMonth] = useState(null)
    const [loading, setLoading] = useState(true)
    const toast = useToast()

    const [allMonths, setAllMonths] = useState(null)
    const [options, setOptions] = useState()

    const [reviewList, setReviewList] = useState(null)

    const datepickerClass = useColorModeValue(datepickerLight, datepickerDark)

    const fetchTopPerformers = async () => {
        setLoading(true)
        const q = query(collection(db, "Top Performers"), orderBy('Month Year Timestamp', 'asc'));
        const querySnapshot = await getDocs(q)
        let data = {}
        let options = []
        let index = 0
        querySnapshot.forEach((doc) => {
            data[`${doc.id}`] = doc.data()
            options.push({ value: doc.id, label: doc.id, index: index++ })
        })

        // Only set displayed month if we have data
        if (options.length > 0) {
            setDisplayedMonth({ label: options[options.length - 1].value, value: options[options.length - 1].value, index: options.length - 1 })
        } else {
            setDisplayedMonth(null)
        }

        setOptions(options)
        setAllMonths(data)
        setLoading(false)
    }

    const deleteTopPerformer = async () => {
        if (!displayedMonth) return;

        try {
            await deleteDoc(doc(db, "Top Performers", displayedMonth.value));
            toast({
                title: `${displayedMonth.value} Top Performers deleted successfully`,
                status: 'success',
                duration: 5000,
                isClosable: true,
                position: 'top'
            });
            await fetchTopPerformers();
        } catch (err) {
            toast({
                title: "Error deleting Top Performers",
                description: err.message,
                status: 'error',
                duration: 5000,
                isClosable: true,
                position: 'top'
            });
        }
    }

    const fetchReviewList = async () => {
        const q = query(collection(db, "Google Reviews Version 2"), orderBy('timestamp', 'desc'), limit(1));

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            setReviewList(doc.data().reviews)
        });

    }
    useEffect(async () => {
        fetchTopPerformers()
        fetchReviewList()
    }, [])

    useEffect(() => {
        console.log(allMonths)
    }, [allMonths])

    return (
        <>
            {!loading && allMonths && displayedMonth &&
                <>

                    <Flex mb={5} mt={5} flexDir={{ base: 'column-reverse', md: 'row' }} justify='center'>
                        <Flex mb={5} flexDir={{ base: 'column', md: 'row' }} align='center' justify='center'>
                            <Heading mr={2} mb={2} textAlign={'center'} as='em' size='md'>
                                Top Performers
                            </Heading>
                            <Flex align="center">
                                <Heading mr={2} textAlign={'center'} as='em' size='md'>
                                    <FormControl w={'215px'}>
                                        <Select
                                            size="md"
                                            onChange={setDisplayedMonth}
                                            value={displayedMonth}
                                            defaultValue={displayedMonth}
                                            options={options}
                                        >
                                        </Select>
                                    </FormControl>
                                </Heading>
                                {SuperAdmin && displayedMonth &&
                                    <IconButton
                                        ml={2}
                                        colorScheme="red"
                                        variant="outline"
                                        size="sm"
                                        icon={<FiTrash2 />}
                                        onClick={deleteTopPerformer}
                                        aria-label="Delete top performer"
                                    />
                                }
                            </Flex>
                        </Flex>
                        {SuperAdmin &&
                            <TopPerformers_Edit
                                fetchTopPerformers={fetchTopPerformers}
                                initialMonthString={displayedMonth.value} />
                        }

                    </Flex>
                    {displayedMonth && <>
                        <SimpleGrid columns={{ base: 1, sm: 2, md: 3 }} spacing='20px' mb={8}>
                            {[
                                { key: 'Duke', displayName: 'The Duke' },
                                { key: 'Rocky', displayName: 'The Rocky' },
                                { key: 'Harry Potter', displayName: 'The Harry Potter' },
                                { key: 'Hustler', displayName: 'The Charlie Hustle' },
                                { key: 'Loan Ranger', displayName: 'The Loan Ranger' },
                                { key: 'Dolly', displayName: 'The Dolly' }
                            ].map((award) => {
                                const awardData = allMonths[displayedMonth.value][award.key];
                                const hasValidUser = awardData &&
                                    awardData.User &&
                                    Object.keys(awardData.User).length > 0;

                                return hasValidUser ? (
                                    <TopPerformers_IndividualBox
                                        key={award.key}
                                        awardName={award.displayName}
                                        amount={awardData.Amount}
                                        user={awardData.User}
                                    />
                                ) : null;
                            })}
                        </SimpleGrid>

                        <TopPerformers_GoogleReviews data={allMonths[displayedMonth.value]['Top Google Reviews']} />

                        <TopPerformers_CompanyStats
                            data={allMonths[displayedMonth.value]['Funded Loans - Company']}
                            reviewData={allMonths[displayedMonth.value]['Top Google Reviews']}
                        />
                    </>}
                </>
            }

            {!loading && Object.keys(allMonths).length === 0 && SuperAdmin && <><Center><Heading size='md' mr='2'>No Top Performers Found</Heading><TopPerformers_Edit initialMonthString={'January 2022'} /></Center></>}
            {reviewList &&
                <TopPerformers_ReviewCarousel_V2 reviewList={reviewList} />
            }
        </>
    )

}