import {
    Flex,
    Box,
    Text,
    Center,
    Progress,
    IconButton,
    FormControl,
    useToast,
    Heading,
    Button,
    InputGroup,
    InputLeftElement,
    Input,
    useColorModeValue
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { collection, getDocs, query, where, orderBy } from 'firebase/firestore';
import { db } from '../utils/init-firebase';
import { GrRefresh } from 'react-icons/gr';
import { FiSearch } from 'react-icons/fi';
import { Select } from "chakra-react-select";
import ReactTable_BrokerDirectory from '../components/Table/ReactTable_BrokerDirectory';
import { useAuth } from '../contexts/AuthContext';
import { useHistory } from 'react-router-dom';

export default function BrokerDirectoryPage() {
    const { SuperAdmin } = useAuth();
    const toast = useToast();
    const history = useHistory();

    const [brokers, setBrokers] = useState([]);
    const [filteredBrokers, setFilteredBrokers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Filters
    const [stateFilter, setStateFilter] = useState(null);
    const [stateOptions, setStateOptions] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');

    // Table columns
    const [columns, setColumns] = useState([
        {
            Header: 'Broker',
            accessor: 'name',
            footer: '',
            disableFilters: true,
            minWidth: '60px'
        },
        {
            Header: 'Email',
            accessor: 'email',
            footer: '',
            disableFilters: true,
            minWidth: '80px'
        },
        {
            Header: 'Phone',
            accessor: 'phoneNo',
            footer: '',
            disableFilters: true,
            minWidth: '40px',
            Cell: ({ cell: { value } }) => {
                // Handle phoneNo as an array
                if (Array.isArray(value) && value.length > 0) {
                    return <Text>{value[0]}</Text>;
                } else if (typeof value === 'string') {
                    return <Text>{value}</Text>;
                } else {
                    return <Text color="gray.500">Not provided</Text>;
                }
            }
        },
        {
            Header: 'NMLS#',
            accessor: 'NMLS',
            footer: '',
            disableFilters: true,
            minWidth: '40px',
            Cell: ({ cell: { value } }) => {
                if (value) {
                    return <Text>{value}</Text>;
                } else {
                    return <Text color="gray.500">Not provided</Text>;
                }
            }
        },
        {
            Header: 'Licensed States',
            accessor: 'states',
            footer: '',
            disableFilters: true,
            minWidth: '250px',
            Cell: ({ cell: { value } }) => {
                const stateBg = useColorModeValue('blue.50', 'blue.800');
                const stateColor = useColorModeValue('black', 'white');
                const emptyColor = useColorModeValue('gray.500', 'gray.400');

                return (
                    <Flex flexWrap="wrap" gap={1}>
                        {value && value.map((state, index) => (
                            <Text
                                key={index}
                                px={2}
                                py={1}
                                borderRadius="md"
                                bg={stateBg}
                                color={stateColor}
                                fontSize="xs"
                            >
                                {state}
                            </Text>
                        ))}
                        {(!value || value.length === 0) && (
                            <Text fontSize="xs" color={emptyColor}>None specified</Text>
                        )}
                    </Flex>
                );
            }
        }
    ]);

    // Function to load broker data
    const loadBrokers = async () => {
        setLoading(true);
        setError(null);

        try {
            // 1. Fetch brokers from users collection
            const brokersRef = collection(db, "users");
            const brokersQuery = query(
                brokersRef,
                // where("Roles", "array-contains", "Mortgage Broker"), // Adjust the query based on how brokers are identified in your system
                orderBy("name", "asc")
            );

            const brokerDocs = await getDocs(brokersQuery);
            const brokerData = brokerDocs.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));

            // 2. Fetch broker license data from broker_licenses collection
            const licenseRef = collection(db, "broker_licenses");
            const licenseDocs = await getDocs(licenseRef);

            // Create a map of broker ID to licensed states
            const licenseMap = {};
            licenseDocs.docs.forEach(doc => {
                licenseMap[doc.id] = doc.data().states || [];
            });

            // 3. Combine the data
            const combinedData = brokerData.map(broker => ({
                ...broker,
                states: licenseMap[broker.id] || []
            }));

            // 4. Build state options for filtering
            const allStates = new Set();
            combinedData.forEach(broker => {
                (broker.states || []).forEach(state => allStates.add(state));
            });

            const stateOpts = Array.from(allStates).sort().map(state => ({
                label: state,
                value: state
            }));

            // Set state
            setBrokers(combinedData);
            setFilteredBrokers(combinedData);
            setStateOptions(stateOpts);
        } catch (err) {
            console.error("Error loading broker data:", err);
            setError(err);
            toast({
                title: "Error loading data",
                description: "There was an error loading the broker directory.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        } finally {
            setLoading(false);
        }
    };

    // Filter brokers based on selected state and search query
    useEffect(() => {
        let filtered = [...brokers];

        // Filter by state if a state is selected
        if (stateFilter) {
            filtered = filtered.filter(broker =>
                broker.states && broker.states.includes(stateFilter.value)
            );
        }

        // Filter by search query if one exists
        if (searchQuery) {
            const query = searchQuery.toLowerCase();
            filtered = filtered.filter(broker =>
                (broker.name && broker.name.toLowerCase().includes(query)) ||
                (broker.email && broker.email.toLowerCase().includes(query)) ||
                (broker.phoneNo && Array.isArray(broker.phoneNo) && broker.phoneNo.length > 0 &&
                    broker.phoneNo[0].toLowerCase().includes(query)) ||
                (broker.NMLS && broker.NMLS.toString().includes(query))
            );
        }

        setFilteredBrokers(filtered);
    }, [stateFilter, searchQuery, brokers]);

    // Reset all filters
    const handleResetFilters = () => {
        setStateFilter(null);
        setSearchQuery('');
    };

    // Load data on initial render
    useEffect(() => {
        loadBrokers();
    }, []);

    return (
        <>
            <Flex maxW={{ base: '90vw', md: '83vw' }} flexDir='row' justify='space-between' mb={4} mt={6}>
                <Heading size="md">FHL Directory</Heading>
                <Flex>
                    {SuperAdmin && (
                        <Button
                            colorScheme="teal"
                            mr={2}
                            onClick={() => history.push('/manage-broker-licenses')}
                        >
                            Manage Licenses
                        </Button>
                    )}
                    <IconButton
                        size='lg'
                        icon={<GrRefresh />}
                        ml='2'
                        variant='ghost'
                        disabled={loading}
                        onClick={loadBrokers}
                        aria-label="Refresh broker data"
                    />
                </Flex>
            </Flex>

            <Flex mb={4} wrap="wrap" gap={2} align="flex-end">
                <FormControl w={{ base: '100%', md: '300px' }}>
                    <InputGroup>
                        <InputLeftElement pointerEvents="none">
                            <FiSearch color="gray.300" />
                        </InputLeftElement>
                        <Input
                            placeholder="Search brokers..."
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                    </InputGroup>
                </FormControl>

                <FormControl w={{ base: '100%', md: '300px' }}>
                    <Select
                        placeholder="Filter by State"
                        value={stateFilter}
                        options={stateOptions}
                        onChange={setStateFilter}
                        isClearable
                    />
                </FormControl>

                <Button onClick={handleResetFilters} variant="outline">
                    Reset Filters
                </Button>
            </Flex>

            {loading ? (
                <Center flexDir="column" mt="150px">
                    <Progress w="200px" size="xs" isIndeterminate />
                    <Text mt={2} colorScheme="gray" fontSize="sm">Loading broker directory...</Text>
                </Center>
            ) : error ? (
                <Center flexDir="column" mt="150px">
                    <Text colorScheme="red" fontSize="md">Error loading broker directory. Please try again.</Text>
                </Center>
            ) : (
                <Box overflowX="auto">
                    <ReactTable_BrokerDirectory
                        cols={columns}
                        data={filteredBrokers}
                        colorScheme="blue"
                        canExpand={false}
                        isExpandable={false}
                        enableExpanding={false}
                        disableExpand={true}
                    />
                </Box>
            )}
        </>
    );
} 