import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Input,
    HStack,
    IconButton,
    Switch,
    FormErrorMessage,
    Avatar,
    Flex
} from '@chakra-ui/react'
import { Button, useDisclosure, FormControl, FormLabel, Text, Heading } from "@chakra-ui/react";
import { useState } from 'react';
import * as React from "react";
import { getFunctions, httpsCallable } from 'firebase/functions'
import { Select } from 'chakra-react-select';
import { useAuth } from '../../contexts/AuthContext';
import { getApp } from "firebase/app";
import { FiX } from 'react-icons/fi';
import { useDragControls } from 'framer-motion';

export default function CreateRCEDoc({ initVals }) {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const { users, currentUser } = useAuth()
    const finalRef = React.useRef(null)
    const dragControls = useDragControls();

    const functions = getFunctions(getApp());
    const generateRCEDoc = httpsCallable(functions, 'generateRealtorConciergeDoc')
    const [sendTo, setSendTo] = useState([''])
    const [from, setFrom] = useState()
    const [fromWithCaret, setFromWithCaret] = useState()

    const [invalidEmail, setInvalidEmail] = useState(false)

    const [subject, setSubject] = useState()
    const [team, setTeam] = useState()
    const [ccTeam, setCCTeam] = useState(true)
    const [openingStatement, setOpeningStatement] = useState()
    const [bodyStatement, setBodyStatement] = useState()
    const [bodyStatement2, setBodyStatement2] = useState()
    const [closingStatement, setClosingStatement] = useState()
    const [closingStatement2, setClosingStatement2] = useState()
    const [loanDetails, setLoanDetails] = useState()
    const [loanDetailsUpdated, setLoanDetailsUpdated] = useState(1)
    const [sendToUpdated, setSendToUpdated] = useState(1)
    const [teamUpdated, setTeamUpdated] = useState(1)


    const [sending, setSending] = useState(false)


    React.useEffect(() => {
        if (users && initVals) {
            let thisTeam = []
            try {
                if (initVals['Loan Officer'] && initVals['Loan Officer'].Name && initVals['Loan Officer'].Name != '') {
                    let userIndex = users.findIndex(x => x.value === initVals['Loan Officer'].uid)
                    if (userIndex > -1) {
                        thisTeam.push(users[userIndex])
                    }
                    else thisTeam.push({ label: initVals['Loan Officer'].Name, value: initVals['Loan Officer'].uid, email: initVals['Loan Officer'].Email, photoURL: initVals['Loan Officer'].photoURL })
                }
                if (initVals.LOA && initVals.LOA.Name && initVals.LOA.Name != '') {
                    let thisTeamIndex = thisTeam.findIndex(x => x.value === initVals.LOA.uid)
                    // If this user is not already included in the team
                    if (thisTeamIndex < 0) {
                        let userIndex = users.findIndex(x => x.value === initVals.LOA.uid)
                        if (userIndex > -1) {
                            thisTeam.push(users[userIndex])
                        }
                        else thisTeam.push({ label: initVals.LOA.Name, value: initVals.LOA.uid, email: initVals.LOA.Email, photoURL: initVals.LOA.photoURL })
                    }

                }
                if (initVals.LC && initVals.LC.Name && initVals.LC.Name != '') {
                    let thisTeamIndex = thisTeam.findIndex(x => x.value === initVals.LC.uid)
                    // If this user is not already included in the team
                    if (thisTeamIndex < 0) {
                        let userIndex = users.findIndex(x => x.value === initVals.LC.uid)
                        if (userIndex > -1) {
                            thisTeam.push(users[userIndex])
                        }
                        else thisTeam.push({ label: initVals.LC.Name, value: initVals.LC.uid, email: initVals.LC.Email, photoURL: initVals.LC.photoURL })
                    }
                }
                if (initVals.CSC && initVals.CSC.Name && initVals.CSC.Name != '') {
                    let thisTeamIndex = thisTeam.findIndex(x => x.value === initVals.CSC.uid)
                    // If this user is not already included in the team
                    if (thisTeamIndex < 0) {
                        let userIndex = users.findIndex(x => x.value === initVals.CSC.uid)
                        if (userIndex > -1) {
                            thisTeam.push(users[userIndex])
                        }
                        else thisTeam.push({ label: initVals.CSC.Name, value: initVals.CSC.uid, email: initVals.CSC.Email, photoURL: initVals.CSC.photoURL })
                    }
                }
            }
            catch (err) {
                console.log('Error creating team for RCE document')
            }
            setTeam(thisTeam)

        }
    }, [initVals, users])

    React.useEffect(() => {
        if (initVals['Loan Details']) {
            let fieldsVals = []
            let loanDetails = initVals['Loan Details']
            if (loanDetails['First Name'] && loanDetails['Last Name']) {
                fieldsVals.push({ field: 'Client Name', value: loanDetails['First Name'] + ' ' + loanDetails['Last Name'] })
            }
            else fieldsVals.push({ field: 'Client Name', value: '' })

            fieldsVals.push({ field: 'Subject Property Address', value: '' })
            fieldsVals.push({ field: 'Buyers Agent', value: '' })
            fieldsVals.push({ field: 'Listing Agent', value: '' })
            if (loanDetails['Title Company Details']) {
                if (loanDetails['Title Company Details']['Point of Contact'] && loanDetails['Title Company Details']['Point of Contact'] != '') {
                    fieldsVals.push({ field: 'Title Agent', value: loanDetails['Title Company Details']['Point of Contact'] })
                }
                else if (loanDetails['Title Company Details']['Name'] && loanDetails['Title Company Details']['Name'] != '') {
                    fieldsVals.push({ field: 'Title Agent', value: loanDetails['Title Company Details']['Name'] })
                }
                else fieldsVals.push({ field: 'Title Agent', value: '' })

            }
            setLoanDetails(fieldsVals)
        }
    }, [initVals])

    React.useEffect(() => {
        if (initVals && users) {
            try {
                if (initVals['Loan Officer'] && initVals['Loan Officer'].Name && initVals['Loan Officer'].Name != '') {
                    let userIndex = users.findIndex(x => x.value === initVals['Loan Officer'].uid)
                    if (userIndex > -1) {
                        setFrom(users[userIndex])
                        setFromWithCaret(`<${users[userIndex].email}>`)
                    }
                }
            }
            catch (err) {

            }
        }
    }, [initVals, users])

    React.useEffect(() => {
        let subj = 'Congratulations!'
        if (initVals['Loan Details']) {
            if (initVals['Loan Details']['Purchase or Refi']) {
                subj += ` / New ${initVals['Loan Details']['Purchase or Refi']} Contract`
            }
            if (initVals['Loan Details']['Last Name']) {
                subj += ` / ${initVals['Loan Details']['Last Name']}`
            }
        }
        setSubject(subj)
    }, [initVals])

    React.useEffect(() => {

        if (initVals['Loan Officer'] && initVals['Loan Officer'].Name) {
            setOpeningStatement(`I'm ${initVals['Loan Officer'].Name}, your Mortgage Broker, and I'd like to introduce you to my team. We all work together to ensure a smooth & seamless transaction.`)
        }
        else setOpeningStatement(`I'd like to introduce you to my team. We all work together to ensure a smooth & seamless transaction.`)
        setBodyStatement('We are your primary points of contact and are focused on providing you with world-class service. A positive experience is our goal, please feel comfortable calling any of us at any time!  Our first step is ensuring our client has a world-class experience is confirming some important information.')
        setBodyStatement2('Can you review the below and let us know if anything needs to be changed?')

        setClosingStatement('Thank you for reviewing this information and trusting us with your business.')
        setClosingStatement2('Please call if you have any questions!')

    }, [initVals])

    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    const handleSetField = (input, index) => {
        setLoanDetails(prevDetails => {
            const newDetails = [...prevDetails];
            newDetails[index] = { ...newDetails[index], field: input };
            return newDetails;
        });
    }

    const handleSetValue = (input, index) => {
        let details = loanDetails
        details[index].value = input
        setLoanDetails(details)
        setLoanDetailsUpdated(Math.random() + 1)
    }

    const addField = () => {
        let details = loanDetails
        details.push({ field: '', value: '' })
        setLoanDetails(details)
        setLoanDetailsUpdated(Math.random() + 1)
    }

    const removeField = (index) => {
        let details = loanDetails
        details.splice(index, 1)
        setLoanDetails(details)
        setLoanDetailsUpdated(Math.random() + 1)
    }


    const handleSetSendTo = (input, index) => {
        let to = sendTo
        to[index] = input
        setSendTo(to)
        setSendToUpdated(Math.random() + 1)
    }

    const addRecipient = () => {
        let to = sendTo
        to.push('')
        setSendTo(to)
        setSendToUpdated(Math.random() + 1)
    }

    const removeRecipient = (index) => {
        let to = sendTo
        to.splice(index, 1)
        setSendTo(to)
        setSendToUpdated(Math.random() + 1)
    }

    const handleSetPhoneNo = (input, index) => {
        let arr = team
        arr[index].phoneNo = input
        setTeam(arr)
        setTeamUpdated(Math.random() + 1)
    }

    const handleSetJobTitle = (input, index) => {
        let arr = team
        arr[index].jobTitle = input
        setTeam(arr)
        setTeamUpdated(Math.random() + 1)

    }

    React.useEffect(() => {
        if (!sendTo || sendTo.length < 1) {
            setInvalidEmail(true)
            return
        }
        for (const email of sendTo) {
            if (!validateEmail(email)) {
                setInvalidEmail(true)
                return
            }
        }
        setInvalidEmail(false)
    }, [sendToUpdated])

    const createDoc = async (values) => {
        setSending(true)
        let vals = {
            to: sendTo,
            from: from,
            subject: subject,
            openingStatement: openingStatement,
            team: team,
            ccTeam: ccTeam,
            bodyStatement: bodyStatement,
            bodyStatement2: bodyStatement2,
            loanDetails: loanDetails,
            closingStatement: closingStatement,
            closingStatement2: closingStatement2,
            file: {
                id: initVals.id,
                parentCollection: 'Clients'
            },
            sender: {
                uid: currentUser.uid,
                name: currentUser.displayName,
                photoURL: currentUser.photoURL,
                email: currentUser.email
            }
        }
        await generateRCEDoc(vals)
        onClose()
        setSending(false)
    }

    return (
        <>
            <Button colorScheme={'blue'} onClick={onOpen}>Email RCE Document</Button>

            <Modal isOpen={isOpen} onClose={onClose} size='3xl'>
                <ModalOverlay />
                <ModalContent>
                    <>
                        <ModalHeader>Email Realtor Concierge Experience Document</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            {sendToUpdated && sendTo && sendTo.map((to, i) => {
                                return (
                                    <>
                                        {i == 0 && <FormLabel>Send To (one email address per line)</FormLabel>}
                                        <HStack key={`send-to-${i}`} dir='row'>
                                            <FormControl isInvalid={to && to != '' && !validateEmail(to)} w={'45%'} p={1}>
                                                <Input value={to} onChange={(e) => handleSetSendTo(e.target.value, i)}></Input>
                                                <FormErrorMessage>Must be a valid email address</FormErrorMessage>

                                            </FormControl>

                                            <IconButton icon={<FiX />} onClick={() => removeRecipient(i)} />
                                        </HStack>
                                    </>

                                )
                            })
                            }
                            <Button m={2} p={4} mb={5} onClick={addRecipient}>Add Recipient</Button>

                            <FormControl p={2}>
                                <FormLabel>
                                    From: {from && from.label && from.label} {fromWithCaret && fromWithCaret}
                                </FormLabel>
                                {/*                 <Select    
                            options={users}
                            value={from}
                            defaultValue={from}
                            onChange={setFrom}
                            placeholder="Select someone..."
                            closeMenuOnSelect={true} 
                        /> */}
                            </FormControl>
                            <FormControl p={2}>
                                <FormLabel>
                                    Subject
                                </FormLabel>
                                <Input value={subject} onChange={(e) => setSubject(e.target.value)} />
                            </FormControl>
                            {/*     <FormControl p={2}>
                        <FormLabel>
                            Opening Statement
                        </FormLabel>
                    <Textarea minH={'120px'} value={openingStatement} onChange={(e) => setOpeningStatement(e.target.value)} /> 
                    </FormControl>*/}
                            <FormControl p={2}>
                                <FormLabel>
                                    Team
                                </FormLabel>
                                <Select
                                    isMulti
                                    name="team"
                                    options={users}
                                    value={team}
                                    defaultValue={team}
                                    onChange={setTeam}
                                    placeholder="Select people..."
                                    closeMenuOnSelect={false}
                                />
                            </FormControl>
                            <FormControl p={2}>
                                <HStack>
                                    <Switch id='cc-team' isChecked={ccTeam} onChange={(e) => setCCTeam(e.target.checked)} />
                                    <FormLabel htmlFor='cc-team' mb='0'>
                                        CC Team On Email
                                    </FormLabel>
                                </HStack>
                            </FormControl>
                            <Flex flexDir={'row'} justify='space-evenly'>
                                {teamUpdated && team?.map((item, i) => (


                                    <Flex justify={'space-between'} fontSize={'xs'} p={3} flexDir={'column'} align='center'>

                                        <Avatar mb={2} size='xl' src={item.photoURL}>
                                        </Avatar>
                                        <Text>{item.label}</Text>
                                        <Input textAlign={'center'} size='sm' value={item.jobTitle} onChange={(e) => handleSetJobTitle(e.target.value, i)}></Input>
                                        <Text>{item.email}</Text>
                                        <Input textAlign={'center'} size={'sm'} value={item.phoneNo} onChange={(e) => handleSetPhoneNo(e.target.value, i)}></Input>
                                    </Flex>


                                ))}
                            </Flex>

                            {/*            <FormControl p={2}>
                        <FormLabel>
                            Body
                        </FormLabel>
                        <Textarea mb={1} minH={'175px'} value={bodyStatement} onChange={(e) => setBodyStatement(e.target.value)} />
                        <Textarea minH={'100px'} value={bodyStatement2} onChange={(e) => setBodyStatement2(e.target.value)} />
                    </FormControl> */}
                            <Heading size='sm' p={2} mb={2} mt={3}>
                                Loan Details
                            </Heading>
                            <HStack >
                                <Text w={'45%'} p={2}>Field </Text>
                                <Text w={'45%'} p={2}>Value </Text>
                            </HStack>
                            {loanDetailsUpdated && loanDetails && loanDetails.map((detail, i) => {
                                return (
                                    <HStack key={`loan-detail-${i}`} dir='row'>
                                        <FormControl w={'45%'} p={1}>
                                            <Input value={detail.field} onChange={(e) => handleSetField(e.target.value, i)}></Input>
                                        </FormControl>
                                        <FormControl w={'45%'} p={1}>
                                            <Input value={detail.value} onChange={(e) => handleSetValue(e.target.value, i)}></Input>
                                        </FormControl>
                                        <IconButton icon={<FiX />} onClick={() => removeField(i)} />
                                    </HStack>
                                )
                            })
                            }
                            <Button m={2} p={4} mb={5} onClick={addField}>Add Field</Button>

                            {/*            <FormControl p={2}>
                        <FormLabel>
                            Closing Statements
                        </FormLabel>
                    <Textarea mb={1} minH={'120px'} value={closingStatement} onChange={(e) => setClosingStatement(e.target.value)} />
                    <Textarea minH={'80px'} value={closingStatement2} onChange={(e) => setClosingStatement2(e.target.value)} />

                    </FormControl> */}
                            {/* 
                    <Box as="pre" marginY={10}>
                        {JSON.stringify(from, null, 2)}

                    </Box>
        */}
                        </ModalBody>

                        <ModalFooter>
                            <Button colorScheme='blue' mr={3} onClick={onClose}>
                                Close
                            </Button>
                            <Button isLoading={sending} disabled={invalidEmail} onClick={() => createDoc()}>Send</Button>
                        </ModalFooter>
                    </>
                </ModalContent>
            </Modal>
        </>
    )

}