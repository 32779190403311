import React, { useState } from 'react';
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Flex,
    useColorModeValue,
    Heading,
    Box,
    Text,
    IconButton,
    Tfoot,
} from '@chakra-ui/react';
import { useTable, useSortBy } from 'react-table';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { FiChevronDown, FiChevronRight } from 'react-icons/fi';

export default function ReactTable_BrokerDirectory({
    cols,
    data,
    title,
    colorScheme = 'blue',
    maxWidth,
    width,
    renderRowSubComponent,
}) {
    const backgroundHeaderColor = useColorModeValue(`${colorScheme}.100`, `${colorScheme}.800`);
    const columns = React.useMemo(() => cols, [cols]);

    const [expandedRows, setExpandedRows] = useState({});

    // Check if the component should have expandable rows
    const hasExpandableRows = !!renderRowSubComponent;

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        footerGroups,
        rows,
        prepareRow,
    } = useTable(
        {
            columns,
            data,
        },
        useSortBy
    );

    // Toggle expanded state for rows
    const toggleExpandedRow = (rowIndex) => {
        setExpandedRows((prev) => ({
            ...prev,
            [rowIndex]: !prev[rowIndex],
        }));
    };

    return (
        <Box overflowX="auto">
            {title && (
                <Heading m="2" size="md" fontWeight="semibold" textTransform="uppercase">
                    {title}
                </Heading>
            )}
            {data && cols && data.length === 0 ? (
                <Text m="2" fontSize="sm" textTransform="uppercase" color="gray">
                    No brokers found
                </Text>
            ) : (
                <Table {...getTableProps()} width={width} maxWidth={maxWidth} mt="1%" boxShadow="md" mb="5">
                    <Thead>
                        {headerGroups.map((headerGroup) => (
                            <Tr {...headerGroup.getHeaderGroupProps()}>
                                {/* Only add expand column if we have expandable rows */}
                                {hasExpandableRows && (
                                    <Th backgroundColor={backgroundHeaderColor} width="50px"></Th>
                                )}

                                {headerGroup.headers.map((column) => (
                                    <Th
                                        backgroundColor={backgroundHeaderColor}
                                        {...column.getHeaderProps(column.getSortByToggleProps())}
                                        minW={column.minWidth}
                                        width={column.width}
                                    >
                                        <Flex alignItems="center">
                                            {column.render('Header')}
                                            {column.isSorted ? (
                                                column.isSortedDesc ? (
                                                    <ChevronDownIcon ml={1} w={4} h={4} />
                                                ) : (
                                                    <ChevronUpIcon ml={1} w={4} h={4} />
                                                )
                                            ) : (
                                                ''
                                            )}
                                        </Flex>
                                    </Th>
                                ))}
                            </Tr>
                        ))}
                    </Thead>
                    <Tbody {...getTableBodyProps()}>
                        {rows.map((row, rowIndex) => {
                            prepareRow(row);
                            const isExpanded = !!expandedRows[rowIndex];

                            return (
                                <React.Fragment key={rowIndex}>
                                    <Tr {...row.getRowProps()}>
                                        {/* Only add expand button if we have expandable rows */}
                                        {hasExpandableRows && (
                                            <Td>
                                                <IconButton
                                                    icon={isExpanded ? <FiChevronDown /> : <FiChevronRight />}
                                                    variant="ghost"
                                                    size="sm"
                                                    onClick={() => toggleExpandedRow(rowIndex)}
                                                    aria-label={isExpanded ? 'Collapse row' : 'Expand row'}
                                                />
                                            </Td>
                                        )}

                                        {/* Regular cells */}
                                        {row.cells.map((cell) => (
                                            <Td
                                                {...cell.getCellProps({
                                                    style: {
                                                        width: cell.column.width,
                                                        maxWidth: cell.column.maxWidth,
                                                        minWidth: cell.column.minWidth,
                                                        fontSize: '14px',
                                                    },
                                                })}
                                            >
                                                {cell.render('Cell')}
                                            </Td>
                                        ))}
                                    </Tr>

                                    {/* Expanded content */}
                                    {isExpanded && hasExpandableRows && (
                                        <Tr>
                                            <Td colSpan={row.cells.length + 1} backgroundColor="gray.50">
                                                {renderRowSubComponent({ row })}
                                            </Td>
                                        </Tr>
                                    )}
                                </React.Fragment>
                            );
                        })}
                    </Tbody>

                    {/* Footer if needed */}
                    {footerGroups.length > 0 && (
                        <Tfoot>
                            {footerGroups.map((group) => (
                                <Tr {...group.getFooterGroupProps()}>
                                    {/* Only add empty cell if we have expandable rows */}
                                    {hasExpandableRows && <Td></Td>}
                                    {group.headers.map((column) => (
                                        <Td
                                            {...column.getFooterProps()}
                                            fontWeight="bold"
                                        >
                                            {column.render('footer')}
                                        </Td>
                                    ))}
                                </Tr>
                            ))}
                        </Tfoot>
                    )}
                </Table>
            )}
        </Box>
    );
} 