import { doc, updateDoc, serverTimestamp, setDoc } from "firebase/firestore";
import { useAuth } from "../../contexts/AuthContext";
import { db, firebase } from "../../utils/init-firebase"
import { Formik } from "formik";
import { Select } from "chakra-react-select";
import {
    InputControl
} from "formik-chakra-ui";
import {
    Box,
    Flex,
    Button,
    Text,
    Center,
    ScaleFade,
    Heading,
} from "@chakra-ui/react";
import { useToast } from "@chakra-ui/react";


export default function TopPerformers_Fields({ fetchTopPerformers, onClose, initialValues, monthYearString, docRef, action }) {
    const { currentUser } = useAuth()
    const { users } = useAuth()

    const toast = useToast()

    const onSubmit = async (values) => {
        try {
            if (initialValues) {
                await updateDoc(doc(db, 'Top Performers', monthYearString), {
                    ...values,
                    'Date Updated': serverTimestamp(),
                    'Updated By': {
                        displayName: currentUser.displayName,
                        uid: currentUser.uid,
                        photoURL: currentUser.photoURL,
                        email: currentUser.email
                    },
                    'Month Year Timestamp': firebase.firestore.Timestamp.fromDate(new Date(monthYearString))
                }
                )
                toast({
                    title: `${monthYearString} Top Performers Updated Succesfully`,
                    status: 'success',
                    duration: 9000,
                    isClosable: true,
                    position: 'top'
                })
            }
            else {
                await setDoc(doc(db, 'Top Performers', monthYearString), {
                    ...values,
                    'Date Created': serverTimestamp(),
                    'Created By': {
                        displayName: currentUser.displayName,
                        uid: currentUser.uid,
                        photoURL: currentUser.photoURL,
                        email: currentUser.email
                    },
                    'Month Year Timestamp': firebase.firestore.Timestamp.fromDate(new Date(monthYearString))
                }
                )
                toast({
                    title: `${monthYearString} Top Performers Created Succesfully`,
                    status: 'success',
                    duration: 9000,
                    isClosable: true,
                    position: 'top'
                })
            }
            await fetchTopPerformers()
        }
        catch (err) {
            toast({
                title: err,
                status: 'error',
                duration: 9000,
                isClosable: true,
            })
        }


    }

    let init = null

    if (initialValues) {
        init = initialValues
    }
    else {
        init = {
            'Duke': {
                'User': {

                },
                'Snippet': '',
                'Amount': '',
            },
            'Rocky': {
                'User': {

                },
                'Snippet': '',
                'Amount': '',
            },
            'Harry Potter': {
                'User': {

                },
                'Snippet': '',
                'Amount': '',
            },
            'Hustler': {
                'User': {

                },
                'Snippet': '',
                'Amount': '',
            },
            'Loan Ranger': {
                'User': {

                },
                'Snippet': '',
                'Amount': '',
            },
            'Dolly': {
                'User': {

                },
                'Snippet': '',
                'Amount': '',
            },
            'Funded Loans - Company': {
                'Dollar Volumne': '',
                'No Transactions': ''
            },
            'Top Google Reviews': {
                'LO': {
                    'User': {

                    },
                    'Amount': ''
                },
                'CSC': {
                    'User': {

                    },
                    'Amount': ''
                },
            }

        }
    }

    return (
        <>
            <ScaleFade in>
                <Formik
                    initialValues={init}
                    onSubmit={onSubmit}
                // validationSchema={action == 'Create' ? createValidation : submitValidation}
                >
                    {({ handleSubmit, values, setFieldValue }) => (
                        <Box

                            p={{ 'base': '0', 'md': "" }}
                            m="5px auto"
                            as="form"
                            onSubmit={handleSubmit}
                        >
                            <Flex direction={{ base: 'column', md: 'row' }} justifyContent='space-around' mb={7}>
                                <Box
                                    width={{ base: '85vw', md: '265px' }}
                                    mb={3}
                                    p={3}
                                    borderWidth={{ 'base': 'none', 'md': "1px" }}
                                    rounded="lg"
                                    shadow='md'>
                                    <Heading mb={3} textAlign='center' textTransform={'uppercase'} size='sm'>The Duke</Heading>
                                    <Select defaultValue={values?.['Duke']?.['User']} onChange={(option) => setFieldValue('Duke.User', option)} options={users}></Select>
                                    <InputControl labelProps={{ textAlign: 'center' }} mt={3} label='Annotation' name='Duke.Amount'></InputControl>
                                </Box>
                                <Box
                                    width={{ base: '85vw', md: '265px' }}
                                    mb={3}
                                    p={3}
                                    borderWidth={{ 'base': 'none', 'md': "1px" }}
                                    rounded="lg"
                                    shadow='md'>
                                    <Heading mb={3} textAlign='center' textTransform={'uppercase'} size='sm'>The Rocky</Heading>
                                    <Select defaultValue={values?.['Rocky']?.['User']} onChange={(option) => setFieldValue('Rocky.User', option)} options={users}></Select>
                                    <InputControl labelProps={{ textAlign: 'center' }} mt={3} label='Annotation' name='Rocky.Amount'></InputControl>
                                </Box>
                                <Box
                                    width={{ base: '85vw', md: '265px' }}
                                    mb={3}
                                    p={3}
                                    borderWidth={{ 'base': 'none', 'md': "1px" }}
                                    rounded="lg"
                                    shadow='md'>
                                    <Heading mb={3} textAlign='center' textTransform={'uppercase'} size='sm'>The Harry Potter</Heading>
                                    <Select defaultValue={values?.['Harry Potter']?.['User']} onChange={(option) => setFieldValue('Harry Potter.User', option)} options={users}></Select>
                                    <InputControl labelProps={{ textAlign: 'center' }} mt={3} label='Annotation' name='Harry Potter.Amount'></InputControl>
                                </Box>
                                <Box
                                    width={{ base: '85vw', md: '265px' }}
                                    mb={3}
                                    p={3}
                                    borderWidth={{ 'base': 'none', 'md': "1px" }}
                                    rounded="lg"
                                    shadow='md'>
                                    <Heading mb={3} textAlign='center' textTransform={'uppercase'} size='sm'>The Charlie Hustle</Heading>
                                    <Select defaultValue={values?.['Hustler']?.['User']} onChange={(option) => setFieldValue('Hustler.User', option)} options={users}></Select>
                                    <InputControl labelProps={{ textAlign: 'center' }} mt={3} label='Annotation' name='Hustler.Amount'></InputControl>
                                </Box>

                            </Flex>
                            <Flex direction={{ base: 'column', md: 'row' }} justifyContent='space-around' mb={7}>
                                <Box
                                    width={{ base: '85vw', md: '265px' }}
                                    mb={3}
                                    p={3}
                                    borderWidth={{ 'base': 'none', 'md': "1px" }}
                                    rounded="lg"
                                    shadow='md'>
                                    <Heading mb={3} textAlign='center' textTransform={'uppercase'} size='sm'>The Loan Ranger</Heading>
                                    <Select defaultValue={values?.['Loan Ranger']?.['User']} onChange={(option) => setFieldValue('Loan Ranger.User', option)} options={users}></Select>
                                    <InputControl labelProps={{ textAlign: 'center' }} mt={3} label='Annotation' name='Loan Ranger.Amount'></InputControl>
                                </Box>
                                <Box
                                    width={{ base: '85vw', md: '265px' }}
                                    mb={3}
                                    p={3}
                                    borderWidth={{ 'base': 'none', 'md': "1px" }}
                                    rounded="lg"
                                    shadow='md'>
                                    <Heading mb={3} textAlign='center' textTransform={'uppercase'} size='sm'>The Dolly</Heading>
                                    <Select defaultValue={values?.['Dolly']?.['User']} onChange={(option) => setFieldValue('Dolly.User', option)} options={users}></Select>
                                    <InputControl labelProps={{ textAlign: 'center' }} mt={3} label='Annotation' name='Dolly.Amount'></InputControl>
                                </Box>
                            </Flex>
                            <Flex direction={{ base: 'column', md: 'row' }} justifyContent='space-evenly' mb={7}>

                                <Box
                                    width={{ base: '85vw', md: '35%' }}
                                    mb={3}
                                    p={3}
                                    borderWidth={{ 'base': 'none', 'md': "1px" }}
                                    rounded="lg"
                                    shadow='md'>
                                    <Heading mb={5} textAlign='center' textTransform={'uppercase'} size='sm'>Funded Loans - Company</Heading>
                                    <InputControl mt={3} label='Dollar Volume' name='Funded Loans - Company.Dollar Volume'></InputControl>
                                    <InputControl mt={3} label='Number of Transactions' name='Funded Loans - Company.No Transactions'></InputControl>
                                </Box>
                                <Box
                                    width={{ base: '85vw', md: '55%' }}
                                    mb={3}
                                    p={3}
                                    borderWidth={{ 'base': 'none', 'md': "1px" }}
                                    rounded="lg"
                                    shadow='md'>
                                    <Heading mb={5} textAlign='center' textTransform={'uppercase'} size='sm'>Top Google Reviews</Heading>
                                    <Flex flexDir={'row'} mb={4}>
                                        <Center><Text mr={3} as='em'>LO&nbsp;</Text></Center>
                                        <Box width='500px' mr={3}>
                                            <Select defaultValue={values['Top Google Reviews']['LO']['User']} placeholder='Select LO' onChange={(option) => setFieldValue('Top Google Reviews.LO.User', option)} options={users}></Select>
                                        </Box>
                                        <InputControl inputProps={{ placeholder: 'Amount' }} name='Top Google Reviews.LO.Amount'></InputControl>

                                    </Flex>
                                    <Flex flexDir={'row'} mb={4}>
                                        <Center><Text as='em'>CSC&nbsp;</Text></Center>
                                        <Box width='500px' mr={3}>
                                            <Select defaultValue={values['Top Google Reviews']['CSC']['User']} placeholder='Select CSC' onChange={(option) => setFieldValue('Top Google Reviews.CSC.User', option)} options={users}></Select>
                                        </Box>
                                        <InputControl inputProps={{ placeholder: 'Amount' }} name='Top Google Reviews.CSC.Amount'></InputControl>

                                    </Flex>
                                    <InputControl mt={8} labelProps={{ textAlign: 'center' }} label='Company Results' name='Top Google Reviews.Company Results'></InputControl>
                                </Box>
                            </Flex>

                            <Flex flexDir={'row'} justify='space-evenly'>
                                <Button colorScheme={'blue'} onClick={() => onClose()}> Close</Button>
                                <Button colorScheme={initialValues ? 'orange' : 'teal'} onClick={() => onSubmit(values)}>{initialValues ? 'Save Changes' : 'Create'}</Button>
                            </Flex>
                            {/*     <Text>
                            {JSON.stringify(values)}
                        </Text> */}
                        </Box>
                    )}

                </Formik>
            </ScaleFade>

        </>
    )
}