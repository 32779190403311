import {
    Box,
    Button,
    Flex,
    Heading,
    Text,
    FormControl,
    FormLabel,
    useToast,
    IconButton,
    Spinner,
    Tag,
    TagLabel,
    TagCloseButton,
    Input,
    Center,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    useDisclosure,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
} from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import { useEffect, useState, useRef } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { db } from '../utils/init-firebase';
import { collection, doc, getDoc, getDocs, query, setDoc, where } from 'firebase/firestore';
import { AddIcon } from '@chakra-ui/icons';

// List of US states for dropdown
const US_STATES = [
    'AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'FL', 'GA',
    'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD',
    'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ',
    'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC',
    'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY'
].map(state => ({ label: state, value: state }));

export default function ManageBrokerLicensesPage() {
    const { SuperAdmin, currentUser } = useAuth();
    const toast = useToast();
    const { isOpen, onOpen, onClose } = useDisclosure();

    const [brokers, setBrokers] = useState([]);
    const [selectedBroker, setSelectedBroker] = useState(null);
    const [selectedStates, setSelectedStates] = useState([]);
    const [loading, setLoading] = useState(false);
    const [savingStates, setSavingStates] = useState(false);
    const [stateInput, setStateInput] = useState('');

    const initialRef = useRef(null);

    // Load brokers
    const loadBrokers = async () => {
        setLoading(true);
        try {
            // Get broker users
            const brokersQuery = query(
                collection(db, 'users'),
                // where('Roles', 'array-contains', 'Mortgage Broker')
            );

            const brokerSnapshot = await getDocs(brokersQuery);
            const brokerList = brokerSnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
                label: doc.data().name || doc.data().email || doc.id,
                value: doc.id
            }));

            setBrokers(brokerList.sort((a, b) => a.label.localeCompare(b.label)));
        } catch (err) {
            console.error('Error loading brokers:', err);
            toast({
                title: 'Error',
                description: 'Failed to load brokers. Please try again.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        } finally {
            setLoading(false);
        }
    };

    // Load broker's licensed states
    const loadBrokerStates = async (brokerId) => {
        if (!brokerId) return;

        setLoading(true);
        try {
            const licenseDocRef = doc(db, 'broker_licenses', brokerId);
            const licenseDoc = await getDoc(licenseDocRef);

            if (licenseDoc.exists()) {
                setSelectedStates(licenseDoc.data().states || []);
            } else {
                setSelectedStates([]);
            }
        } catch (err) {
            console.error('Error loading broker states:', err);
            toast({
                title: 'Error',
                description: 'Failed to load broker states. Please try again.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        } finally {
            setLoading(false);
        }
    };

    // Save broker's licensed states
    const saveBrokerStates = async () => {
        if (!selectedBroker) return;

        setSavingStates(true);
        try {
            const licenseDocRef = doc(db, 'broker_licenses', selectedBroker.id);
            await setDoc(licenseDocRef, {
                states: selectedStates,
                updatedAt: new Date(),
                updatedBy: currentUser.uid
            }, { merge: true });

            toast({
                title: 'Success',
                description: 'Broker license states updated successfully.',
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
        } catch (err) {
            console.error('Error saving broker states:', err);
            toast({
                title: 'Error',
                description: 'Failed to save broker states. Please try again.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        } finally {
            setSavingStates(false);
        }
    };

    // Add a state to the selected states
    const addState = (state) => {
        if (!state || selectedStates.includes(state)) return;
        setSelectedStates([...selectedStates, state]);
        setStateInput('');
        onClose();
    };

    // Remove a state from the selected states
    const removeState = (state) => {
        setSelectedStates(selectedStates.filter(s => s !== state));
    };

    // Handle broker selection change
    const handleBrokerChange = (broker) => {
        setSelectedBroker(broker);
        if (broker) {
            loadBrokerStates(broker.id);
        } else {
            setSelectedStates([]);
        }
    };

    // Load brokers on initial render
    useEffect(() => {
        if (SuperAdmin) {
            loadBrokers();
        }
    }, [SuperAdmin]);

    // If not a super admin, render nothing
    if (!SuperAdmin) {
        return (
            <Center h="50vh">
                <Text>You do not have permission to access this page.</Text>
            </Center>
        );
    }

    return (
        <Box maxW="container.lg" mx="auto" py={6}>
            <Heading size="lg" mb={6}>Manage Broker Licenses</Heading>

            <FormControl mb={6}>
                <FormLabel>Select Broker</FormLabel>
                <Select
                    options={brokers}
                    value={selectedBroker}
                    onChange={handleBrokerChange}
                    placeholder="Select a broker"
                    isLoading={loading}
                    isClearable
                />
            </FormControl>

            {selectedBroker && (
                <Box mt={4} p={4} borderWidth={1} borderRadius="md">
                    <Flex justify="space-between" align="center" mb={4}>
                        <Heading size="md">Licensed States for {selectedBroker.label}</Heading>
                        <IconButton
                            icon={<AddIcon />}
                            onClick={onOpen}
                            aria-label="Add state"
                            colorScheme="blue"
                        />
                    </Flex>

                    <Flex wrap="wrap" gap={2} mb={4}>
                        {selectedStates.length === 0 ? (
                            <Text color="gray.500">No states added yet</Text>
                        ) : (
                            selectedStates.map(state => (
                                <Tag
                                    key={state}
                                    size="md"
                                    borderRadius="full"
                                    variant="solid"
                                    colorScheme="blue"
                                >
                                    <TagLabel>{state}</TagLabel>
                                    <TagCloseButton onClick={() => removeState(state)} />
                                </Tag>
                            ))
                        )}
                    </Flex>

                    <Button
                        mt={4}
                        colorScheme="teal"
                        onClick={saveBrokerStates}
                        isLoading={savingStates}
                        loadingText="Saving"
                    >
                        Save Changes
                    </Button>
                </Box>
            )}

            {/* Add State Modal */}
            <Modal
                isOpen={isOpen}
                onClose={onClose}
                initialFocusRef={initialRef}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Add Licensed State</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <FormControl>
                            <FormLabel>State</FormLabel>
                            <Select
                                options={US_STATES.filter(state => !selectedStates.includes(state.value))}
                                onChange={(option) => addState(option.value)}
                                placeholder="Select state"
                                ref={initialRef}
                            />
                        </FormControl>
                    </ModalBody>
                    <ModalFooter>
                        <Button variant="ghost" mr={3} onClick={onClose}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Box>
    );
} 